import { useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";

// Custom hook to parse cookies and return the token
const useTokenFromCookies = () => {
  const [cookies] = useCookies(["token"]);
  return cookies.token || null;
};

const useAuth = () => {
  // Getting token value stored in cookies using the custom hook
  const TOKEN = useTokenFromCookies();
  console.log("TOKEN", TOKEN);

  const PUBLIC_ROUTES = [
    "login",
    "forgot-password",
    "register",
    "documentation",
  ];
  const isPublicPage = PUBLIC_ROUTES.some((r) =>
    window.location.href.includes(r)
  );

  useEffect(() => {
    if (!TOKEN && !isPublicPage) {
      window.location.href = "/login";
    } else {
      axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

      const requestInterceptor = axios.interceptors.request.use(
        function (config) {
          document.body.classList.add("loading-indicator");
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );

      const responseInterceptor = axios.interceptors.response.use(
        function (response) {
          document.body.classList.remove("loading-indicator");
          return response;
        },
        function (error) {
          document.body.classList.remove("loading-indicator");
          return Promise.reject(error);
        }
      );

      return () => {
        axios.interceptors.request.eject(requestInterceptor);
        axios.interceptors.response.eject(responseInterceptor);
      };
    }
  }, [TOKEN, isPublicPage]);
};

export default useAuth;
