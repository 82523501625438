import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getUserContent = createAsyncThunk(
  "/user/getUserContent",
  async () => {
    try {
      const { data } = await axios.get("/api/profile", {});
      console.log("responseresponse", data);
      return data;
    } catch (error) {
      return "";
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    user: {},
  },
  reducers: {
    addNewLead: (state, action) => {
      let { newLeadObj } = action.payload;
      state.user = [...state.user, newLeadObj];
    },

    // deleteLead: (state, action) => {
    //   let { index } = action.payload;
    //   state.user.splice(index, 1);
    // },
  },

  extraReducers: {
    [getUserContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserContent.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    [getUserContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

// export const { addNewLead, deleteLead } = userSlice.actions;

export default userSlice.reducer;
