import axios from "axios";
import { useCookies } from "react-cookie";

// Get the token from cookies
const useInitializeApp = () => {
  const [cookies] = useCookies(["token"]);
  const token = cookies.token;

  // Setting base URL for all API requests via axios

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  //   axios.defualts.headers.common["Content-Type"] = "application/json";

  // Set up Axios instance with token
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
  } else {
    // Prod build code
    // Removing console.log from prod
    console.log = () => {};
    // init analytics here
  }
};

export default useInitializeApp;
