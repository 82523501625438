import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getPaymentContent = createAsyncThunk(
  "/payments/content",
  async () => {
    const { data } = await axios.get("/api/payments", {});
    console.log("response from payments", data);
    return data;
  }
);

export const updatePayment = createAsyncThunk(
  "payments/updatePayment",
  async (payment) => {
    const { data } = await axios.put(`/api/payments/${payment.id}`, payment);
    return data;
  }
);

export const paymentSlice = createSlice({
  name: "payments",
  initialState: {
    isLoading: false,
    payments: [],
  },
  reducers: {
    addNewPayemnt: (state, action) => {
      let { newLeadObj } = action.payload;
      state.payments = [...state.payments, newLeadObj];
    },

    // deleteLead: (state, action) => {
    //   let { index } = action.payload;
    //   state.subscribers.splice(index, 1);
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPaymentContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentContent.fulfilled, (state, action) => {
        state.payments = action.payload;
        state.isLoading = false;
      })
      .addCase(getPaymentContent.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updatePayment.fulfilled, (state, action) => {
        const index = state.payments.findIndex(
          (payment) => payment.id === action.payload.id
        );
        if (index !== -1) {
          state.payments[index] = action.payload;
        }
      });
  },
});

export const { addNewPayemnt } = paymentSlice.actions;

export default paymentSlice.reducer;
