import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSubscribersContent = createAsyncThunk(
  "subscribers/getSubscribersContent",
  async ({ page, perPage, search, region, startDate, endDate }) => {
    const { data } = await axios.get("/api/profiles", {
      params: {
        page,
        per_page: perPage,
        search,
        region,
        start_date: startDate,
        end_date: endDate,
      },
    });

    return data;
  }
);

const subscriberSlice = createSlice({
  name: "subscribers",
  initialState: {
    isLoading: false,
    subscribers: [],
    totalPages: 1,
    currentPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscribersContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscribersContent.fulfilled, (state, action) => {
        state.subscribers = action.payload.profiles.data;
        state.totalPages = action.payload.profiles.total || 1; // Assuming totalPages is provided in the response
        state.currentPage = action.payload.profiles.current_page || 1;
        state.isLoading = false;
      })
      .addCase(getSubscribersContent.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default subscriberSlice.reducer;
