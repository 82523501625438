import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import userSlice from "../features/user/userSlice";
import paymentSlice from "../features/payments/payemntSlice";
import reviewSlice from "../features/reviews/reviewSlice";
import subscriberSlice from "../features/subscribers/subscriberSlice";
import skillSlice from "../features/skills/skillSlice";
const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  subscribers: subscriberSlice,
  skills: skillSlice,
  user: userSlice,
  payment: paymentSlice,
  review: reviewSlice,
};

export default configureStore({
  reducer: combinedReducer,
});
