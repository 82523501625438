import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getReviewContent = createAsyncThunk(
  "/reviews/content",
  async () => {
    const { data } = await axios.get("/api/reviews", {});
    console.log("response from payments", data);
    return data;
  }
);

export const updateReviewContent = createAsyncThunk(
  "/reviews/update",
  async ({ id, reviewData }) => {
    const { data } = await axios.put(`/api/reviews/${id}`, reviewData);
    return { id, reviewData };
  }
);

const reviewSlice = createSlice({
  name: "reviews",
  initialState: {
    isLoading: false,
    reviews: [],
  },
  reducers: {
    addNewReview: (state, action) => {
      let { newLeadObj } = action.payload;
      state.reviews = [...state.reviews, newLeadObj];
    },
    deleteReview: (state, action) => {
      let { index } = action.payload;
      state.reviews.splice(index, 1);
    },
  },
  extraReducers: {
    [getReviewContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getReviewContent.fulfilled]: (state, action) => {
      state.reviews = action.payload;
      state.isLoading = false;
    },
    [getReviewContent.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateReviewContent.fulfilled]: (state, action) => {
      const { id, reviewData } = action.payload;
      const index = state.reviews.findIndex((review) => review.id === id);
      if (index !== -1) {
        state.reviews[index] = { ...state.reviews[index], ...reviewData };
      }
    },
  },
});

export const { addNewReview, deleteReview } = reviewSlice.actions;
export default reviewSlice.reducer;
