import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSkillsContent = createAsyncThunk(
  "skills/getSkillsContent",
  async ({ page, perPage, search, is_approved }) => {
    const response = await axios.get("/api/skills", {
      params: { page, perPage, search, is_approved },
    });
    return response.data.skills;
  }
);

export const addSkill = createAsyncThunk("skills/addSkill", async (skill) => {
  const response = await axios.post("/api/skill", skill);
  return response.data.skill;
});

export const updateSkill = createAsyncThunk(
  "skills/updateSkill",
  async ({ id, skill }) => {
    const response = await axios.put(`/api/skill/${id}`, skill);
    return response.data.skill;
  }
);

export const deleteSkill = createAsyncThunk(
  "skills/deleteSkill",
  async (id) => {
    await axios.delete(`/api/skill/delete/${id}`);
    return id;
  }
);

export const toggleApproveSkill = createAsyncThunk(
  "skills/toggleApproveSkill",
  async (id) => {
    const response = await axios.post(`/api/skill/${id}/toggle-approve`);
    return response.data.skill;
  }
);

const skillsSlice = createSlice({
  name: "skills",
  initialState: {
    skills: [],
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSkillsContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSkillsContent.fulfilled, (state, action) => {
        state.skills = action.payload.data;
        state.totalPages = action.payload.last_page;
        state.currentPage = action.payload.current_page;
        state.isLoading = false;
      })
      .addCase(getSkillsContent.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addSkill.fulfilled, (state, action) => {
        state.skills.push(action.payload);
      })
      .addCase(updateSkill.fulfilled, (state, action) => {
        const index = state.skills.findIndex(
          (skill) => skill.id === action.payload.id
        );
        if (index !== -1) {
          state.skills[index] = action.payload;
        }
      })
      .addCase(deleteSkill.fulfilled, (state, action) => {
        state.skills = state.skills.filter(
          (skill) => skill.id !== action.payload
        );
      })
      .addCase(toggleApproveSkill.fulfilled, (state, action) => {
        const index = state.skills.findIndex(
          (skill) => skill.id === action.payload.id
        );
        if (index !== -1) {
          state.skills[index] = action.payload;
        }
      });
  },
});

export default skillsSlice.reducer;
