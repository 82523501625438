import React, { lazy, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { themeChange } from 'theme-change';
import { useCookies } from 'react-cookie'; // Import useCookies hook
import useAuth from './app/auth';
// import initializeApp from './app/init';
import useInitializeApp from './app/init';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Register = lazy(() => import('./pages/Register'));
const Documentation = lazy(() => import('./pages/Documentation'));

function AppWrapper() {
  const token = useAuth();
  const [cookies, setCookie] = useCookies(['token']); // Use useCookies hook here
  useInitializeApp();
  useEffect(() => {
    // Initialize the application
   
    
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  return (
    <App token={token} />
  );
}

function App({ token }) {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={token ? "/app/welcome" : "/login"} replace />}/>

        </Routes>
      </Router>
    </>
  );
}

export default AppWrapper;
